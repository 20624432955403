      
<template>
  <div>
    <vx-card v-if="campaignOptions.length > 0">
      <div class="vx-row find-people">
        <!-- Custom Campaign Keyword Summary table-->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <div class="card-title more-card-title pb-0">
            <h2 class="mb-0">Custom Campaign Keyword Summary</h2>
          </div>
          <!-- Campaign, Date Picker, Update Dashboard -->
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="domain-info mb-8">
                <!-- Campaign -->
                <select-filter 
                  labelname="Campaign"
                  :options="campaignOptions"
                  optionsName="Campaign_Name"
                  :value="campaignSelected"
                  @input="newCampaign => {campaignSelected = newCampaign;}"
                ></select-filter>
                <!-- Date Picker -->
                <date-range-picker
                  :fromDateValue="fromDate"
                  :toDateValue="toDate"
                  @inputFromDate="
                    newFromDate => {
                      fromDate = newFromDate;
                    }
                  "
                  @inputToDate="
                    newToDate => {
                      toDate = newToDate;
                    }
                  "
                ></date-range-picker>
                <!-- Update Dashboard -->
                <vs-button type="filled" class="small-btn bg-actionbutton" @click="getKeywords()">{{ LabelConstant.buttonLabelUpdateDashboard }}</vs-button>
              </div>
            </div>
          </div>
          <div class="vx-row mb-8">
            <div class="vx-col w-full">
              <p>
                You are receiving about {{percentAvailableFootprints}}% of the available footprints for this campaign. You have {{crawlers}} crawler assigned to this campaign.
              </p>
            </div>
            <div class="vx-col w-full my-3">
              <vs-progress
                :height="12"
                :percent="percentAvailableFootprints"
                color="primary"
              ></vs-progress>
            </div>
            <div class="vx-col w-full my-3">
              <p>
                Each custom campaign crawler is has a maximum capacity to detect {{footprintsPerMonth|tableRecordNumberFormatter}} footprints per month. 
                Sometimes when keywords are too broad, they will dominate the collection process. 
                This report will allow you to visualize your results and see approximately how many footprints you may be missing. 
                You can also add additional crawler resources to this campaign by clicking below:
              </p>
            </div>
            <div class="vx-col w-full text-center">
              <vs-button
                title="Add Campaign"
                color="primary"
                type="filled"
                class="mr-2 mb-1"
                @click="purchaseAdditionalCampaignPopup = true, campaignCountSummary()"
                >Purchase additional custom campaign crawlers</vs-button
              >
            </div>
          </div>
          <div class="common-table">
            <div class="table-row-select">
              <vs-select
                :label="LabelConstant.inputLabelShowEntries"
                class="show-select"
                v-model="perPage"
                autocomplete
              >
                <vs-select-item  
                  :key="index"
                  :value="item.value"
                  :text="item.text"
                  v-for="(item, index) in recordList"
                />
              </vs-select>
            </div>
            <!-- table -->
            <vs-table :data="keywordList" :max-items="perPage" pagination search >
              <template slot="thead">
                <vs-th sort-key="Keyword">Keywords</vs-th>
                <vs-th sort-key="Companies">Companies</vs-th>
                <vs-th sort-key="Footprints">Footprints</vs-th>
                <vs-th sort-key="Utilzation">Utilization</vs-th>
                <vs-th>Actions</vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].Keyword">
                    {{ data[indextr].Keyword }}
                  </vs-td>
                  <vs-td :data="data[indextr].Companies">
                    {{ data[indextr].Companies|tableRecordNumberFormatter }}
                  </vs-td>
                  <vs-td :data="data[indextr].Footprints">
                    {{ data[indextr].Footprints|tableRecordNumberFormatter }}
                  </vs-td>
                  <vs-td :data="data[indextr].Utilzation">
                    {{ data[indextr].Utilzation }}
                  </vs-td>
                  <vs-td width="90">
                    <div class="table-action">
                      <vs-button
                        title="Delete"
                        color="danger"
                        type="filled"
                        icon-pack="feather"
                        icon="icon-trash-2"
                        class="mr-0"
                        @click="deleteKeywordPopupActive = true, deletableKeywordId = data[indextr].Id, deletableKeywordName = data[indextr].Keyword"
                      ></vs-button>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
    </vx-card>
    
    <!-- if campaing not available  selected -->
    <vx-card v-else>
      <div class="vx-row">
        <div class="vx-col w-full text-center">
          <div class="coming-soon sm:w-1/2 w-full py-5 mx-auto">
            <img src="../../../src/assets/images/maintance.png" class="d-inline-block w-full" />
            <router-link :to="{ name: 'IntentCampaigns' }"><h4 class="text-primary py-5">Please configure a campaign to get started</h4></router-link>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- Confirmation Purchase or Cancel Additional Custom Campaign Popup --> 
    <vs-popup class="transaction-popup" :active.sync="addDeleteCustomCampaignConfirmActive">             
      <div class="transaction-details">
          <div class="popup-icon">
            <img v-show="userActionType == 'add'" src="../../assets/images/warning.svg">
            <img v-show="userActionType == 'delete'" src="../../assets/images/trash.svg">
          </div>
          <h2 v-show="userActionType == 'add'">Purchase Additional Custom Campaign?</h2>
          <h2 v-show="userActionType == 'delete'">Remove Custom Campaign?</h2>
          <p v-show="userActionType == 'add'">{{additionalCustomerCampaign}}</p>
          <p v-show="userActionType == 'delete'">{{deletionCustomCampaign}}</p>
          <div class="float-left mt-6 w-full text-center">             
            <vs-button @click="purchaseAdditionalCampaign()" v-show="userActionType == 'add'" color="success" class="mt-0 sm:w-auto w-full" type="filled">{{ LabelConstant.buttonLabelPurchase }}</vs-button>
            <vs-button @click="deletePurchasedCampaign()" v-show="userActionType == 'delete'" color="danger"  class="mt-0 sm:w-auto w-full"  type="filled">{{ LabelConstant.buttonLabelRemove }}</vs-button>
            <vs-button @click="addDeleteCustomCampaignConfirmActive=false" color="grey" type="border" class="grey-btn sm:ml-4  ml-0 mt-2 sm:mt-0 sm:w-auto w-full">{{ LabelConstant.buttonLabelCancel }}</vs-button>            
          </div>
      </div>            
    </vs-popup>

    <!-- Purchase or Cancel Additional Custom Campaigns popup -->
    <vs-popup class="popup-width" title="Purchase or Cancel Additional Custom Campaigns" :active.sync="purchaseAdditionalCampaignPopup">
        <template v-if="!isActiveFreeTrial && !isReseller">
            <div class="vx-row mb-3" >
                <div class="vx-col md:w-1/2 w-full">
                    <ul class="account-summary p-4">
                        <li class="pb-2 w-full">
                            <h4>Custom Campaigns included with plan</h4>
                            <span>{{customCampaignsIncluded}}</span>
                        </li>
                        <li class="pl-0 pb-2 w-full">
                            <h4>Additional Custom Campaigns purchased</h4>
                            <span>{{customCampaignsPurchased}}</span>
                        </li>
                        <li class="w-full pb-2">
                            <h4>Total Custom Campaigns Provisioned</h4>
                            <span>{{customCampaignsProvisioned}}</span>                      
                        </li>
                    </ul>
                </div>
                <div class="vx-col w-full option-section">
                    <div class="card-title">
                        <h2> Options </h2>                        
                    </div>
                    <div class="vx-row">
                        <div class="vx-col w-full mb-8">
                            <div class="option-item">
                                <vs-radio v-model="userActionType" vs-value="add" :color="addCustomCampaignRadioColor" @change="changeColor()">
                                  <span :class="addCustomCampaignTextColor">I would like to purchase an additional custom campaign</span>
                                </vs-radio>
                                <ul>
                                    <li>
                                        <span>Purchase additional custom Campaigns</span>
                                        <v-select
                                          v-model="additionalCustomerCampaign"
                                          :options="additionalCustomCampaignOptions"
                                          :clearable="false"
                                        />
                                        <span>$199/month</span>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                        <div class="vx-col w-full mb-8" v-show="campaignsAvailableToDelete > 0">
                        <!-- <div class="vx-col w-full mb-8"> -->
                            <div class="option-item">
                                <vs-radio v-model="userActionType" vs-value="delete" :color="deleteCustomCampaignRadioColor" @change="changeColor()">
                                  <span :class="deletionCustomCampaignTextColor">I would like to cancel an additional custom campaign</span>
                                </vs-radio>
                                <ul>
                                    <li>
                                        <span>Cancel additional custom Campaigns</span>
                                        <v-select 
                                          v-model="deletionCustomCampaign" 
                                          :options="deletionCustomCampaignOptions" 
                                          :clearable="false"
                                        />
                                        <span>$-199/month</span>
                                    </li>                                
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="isActiveFreeTrial && !isReseller">
          <div class="vx-row mb-3">
            <div class="vx-col text-left w-full">
            <div class="popup-icon">
                <feather-icon text-info icon="InfoIcon"></feather-icon>
              </div>
              <h4 class="text-primary">This feature is not available for free trials.  Please contact your administrator</h4>
            </div>
          </div>
        </template>
        <template v-if="isReseller">
          <div class="vx-row mb-3">
            <div class="vx-col text-left w-full">
            <div class="popup-icon">
                <feather-icon text-info icon="InfoIcon"></feather-icon>
              </div>
              <h4 class="text-primary">Please contact your administrator for additional purchases</h4>
            </div>
          </div>
        </template>
        <div class="float-right mb-8">
            <vs-button v-if="!isActiveFreeTrial && !isReseller" @click="addDeleteCustomCampaignConfirmActive=true" v-show="userActionType == 'add'" type="filled" color="primary">Purchase Additional Campaigns</vs-button>
            <vs-button v-if="!isActiveFreeTrial && !isReseller" @click="addDeleteCustomCampaignConfirmActive=true" v-show="userActionType == 'delete'" color="danger" type="filled">Remove Additional Campaigns</vs-button>
            <vs-button @click="cancelPurchase()" color="grey" type="border" class="ml-4 grey-btn" >{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
    
    <!-- Delete Keyword -->
    <vs-popup class="delete-popup" :active.sync="deleteKeywordPopupActive">
      <template>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <div class="popup-icon">
              <feather-icon icon="Trash2Icon"></feather-icon>
            </div>
            <h4>Delete Keyword?</h4>
            <span class="deleted-no">{{ deletableKeywordName }}</span>
          </div>
        </div>
      </template>
      <div class="float-right mt-6 mb-8">
        <vs-button
          type="filled"
          @click="deleteKeyword()"
          color="danger"
          >{{ LabelConstant.buttonLabelDelete }}</vs-button
        >
        <vs-button
          @click="cancelDeleteKeyword()"
          color="grey"
          type="border"
          class="ml-4 grey-btn"
          >{{ LabelConstant.buttonLabelCancel }}</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

  <script>
import vSelect from "vue-select";
import InputTag from "vue-input-tag";
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
export default {
  components: {
    "v-select": vSelect,
    InputTag,
    SelectFilter,
    DateRangePicker,
  },
  data(){
    return{
      isReseller: false,
      isActiveFreeTrial: false,
      perPage: 5,
      recordList: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],

      purchaseAdditionalCampaignPopup: false,
      customCampaignsIncluded: 0,
      customCampaignsPurchased: 0,
      customCampaignsProvisioned: 0,
      customCampaignsAvailableToProvision: 0,
      prebuiltCampaignsIncluded: 0,
      prebuiltCampaignsProvisioned: 0,
      prebuiltCampaignsAvailableToProvision: 0,
      campaignsAvailableToDelete: 0,
      
      campaignOptions: [],
      campaignSelected: {},

      //From Date
      fromDate: null,
      //To Date
      toDate: null,
      keywordList: [],
      crawlers: 0,
      footprintsPerMonth: 0,
      percentAvailableFootprints: 0,

      deleteKeywordPopupActive: false,
      deletableKeywordId: null,
      deletableKeywordName: null,

      userActionType: 'add',
      addCustomCampaignRadioColor: 'success',
      addCustomCampaignTextColor: 'text-success',
      additionalCustomerCampaign : 1,
      additionalCustomCampaignOptions: [],


      deleteCustomCampaignRadioColor: 'secondary',
      deletionCustomCampaignTextColor: '',
      deletionCustomCampaign: 1,
      deletionCustomCampaignOptions: [],

      addDeleteCustomCampaignConfirmActive: false,
    }
  },
  created() {
    this.setFromDate();
    this.setToDate();
    this.getCampaign();
    this.generateAdditionalUserOptions();
  },
  mounted() {
      let uid = this.$route.params.id;
      let VVtoken = localStorage.getItem("VVtoken");
      let token = JSON.parse(VVtoken);
      this.isReseller = token[uid].isReseller
      this.isActiveFreeTrial = token[uid].isActiveFreeTrial
  },
  methods: {
    getCampaign(){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCustomCampaignKeywordSummary/GetCampaigns").then((response) => {
        let data = response.data;
        this.campaignOptions = data
        this.campaignSelected = this.campaignOptions[0]
        this.getKeywords()
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    getKeywords() {
      let input = {
        CampaignId: this.campaignSelected.Id,
        Date_Start: this.fromDate,
        Date_End: this.toDate
      };
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneCustomCampaignKeywordSummary/getKeywords", input).then((response) => {
        let data = response.data;
        this.keywordList = data;
        this.getCampaignProgress()
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    getCampaignProgress() {
      let input = {
        CampaignId: this.campaignSelected.Id,
        Date_Start: this.fromDate,
        Date_End: this.toDate
      };
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneCustomCampaignKeywordSummary/GetCampaignProgress", input).then((response) => {
        let data = response.data;
        this.crawlers = data.Crawlers
        this.footprintsPerMonth = data.FootprintsPerMonth
        this.percentAvailableFootprints = data.PercentAvailableFootprints
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    cancelDeleteKeyword(){
      this.deleteKeywordPopupActive = false
      this.deletableKeywordId = null
      this.deletableKeywordName = null
    },
    deleteKeyword(){
      this.$vs.loading();
      this.axios.post("/ws/FindAnyoneCustomCampaignKeywordSummary/DeleteKeyword?Id="+this.deletableKeywordId).then(() => {
        this.$vs.notify({
          title: "Success",
          text: "Keyword deleted!",
          color: "success",
          iconPack: "feather",
          icon: "icon-check",
          position: "top-right",
          time: 4000,
        });
        this.getKeywords()
        this.cancelDeleteKeyword()
        this.$vs.loading.close();
      }).catch((e) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
        });
        this.showError(e);
      });
    },
    async campaignCountSummary(){
      this.$vs.loading();
      await this.axios.get("/ws/FindAnyoneCampaign/CampaignCountSummary").then(response => {
        let data = response.data;
        this.customCampaignsIncluded = data.Custom_Campaigns_Included
        this.customCampaignsPurchased = data.Custom_Campaigns_Purchased
        this.customCampaignsProvisioned = data.Custom_Campaigns_Provisioned
        this.customCampaignsAvailableToProvision = data.Custom_Campaigns_Available_To_Provision
        this.prebuiltCampaignsIncluded = data.Prebuilt_Campaigns_Included
        this.prebuiltCampaignsProvisioned = data.Prebuilt_Campaigns_Provisioned
        this.prebuiltCampaignsAvailableToProvision = data.Prebuilt_Campaigns_Available_To_Provision
        this.generateDeletionCustomCampaignOptions()
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.showError(e);
      });
    },
    generateDeletionCustomCampaignOptions(){
      this.deletionCustomCampaignOptions = []
      this.campaignsAvailableToDelete = 0;
      if(this.customCampaignsPurchased > 0 && this.customCampaignsProvisioned > 0){
        this.campaignsAvailableToDelete = (this.customCampaignsPurchased) - (this.customCampaignsProvisioned - this.customCampaignsIncluded)
      }else{
        this.campaignsAvailableToDelete = this.customCampaignsPurchased
      }
      for (let i = 1; i <= this.campaignsAvailableToDelete; i++) {
          this.deletionCustomCampaignOptions.push(i)
      }
    },
    generateAdditionalUserOptions(){
      this.additionalCustomCampaignOptions = []
      for (let i = 1; i <= 10; i++) {
          this.additionalCustomCampaignOptions.push(i)
      }
    },
    changeColor(){
      if(this.userActionType == 'add'){
        this.addCustomCampaignRadioColor = 'success'
        this.addCustomCampaignTextColor = 'text-success'
        this.deleteCustomCampaignRadioColor = 'secondary'
        this.deletionCustomCampaignTextColor = ''
      }else{
        this.addCustomCampaignRadioColor = 'secondary'
        this.addCustomCampaignTextColor = ''
        this.deleteCustomCampaignRadioColor = 'danger'
        this.deletionCustomCampaignTextColor = 'text-danger'
      }
    },
    cancelPurchase(){
      this.purchaseAdditionalCampaignPopup = false

      this.userActionType = 'add'
      this.addCustomCampaignRadioColor = 'success'
      this.addCustomCampaignTextColor = 'text-success'
      this.additionalCustomerCampaign = 1
      // this.additionalCustomCampaignOptions = []


      this.deleteCustomCampaignRadioColor = 'secondary'
      this.deletionCustomCampaignTextColor = ''
      this.deletionCustomCampaign = 1
      // this.deletionCustomCampaignOptions = []
    },
    purchaseAdditionalCampaign(){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCampaign/PurchaseAdditionalCampaign?qty="+this.additionalCustomerCampaign).then(() => {
        this.$vs.notify({
          title:'Success',
          text: 'Your additional campaign have been updated successfully.',
          color: "success",
          iconPack: 'feather',
          icon:'icon-check',
          position: "top-right",
          time:4000
        });
        this.addDeleteCustomCampaignConfirmActive = false
        this.cancelPurchase()
        this.campaignCountSummary()
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.$vs.notify({
          title:'Error',
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: 'feather',
          icon:'icon-alert-circle',
          position:"top-right",
          time:4000
        });
        this.showError(e);
      });  
    },
    deletePurchasedCampaign(){
      this.$vs.loading();
      this.axios.get("/ws/FindAnyoneCampaign/RemoveAdditionalCampaign?qty="+this.deletionCustomCampaign).then(() => {
        this.$vs.notify({
          title:'Success',
          text: 'Your additional campaign have been updated successfully.',
          color: "success",
          iconPack: 'feather',
          icon:'icon-check',
          position: "top-right",
          time:4000
        });
        this.addDeleteCustomCampaignConfirmActive = false
        this.cancelPurchase()
        this.campaignCountSummary
        this.$vs.loading.close();
      }).catch(e => {
        this.$vs.loading.close();
        this.$vs.notify({
          title:'Error',
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: 'feather',
          icon:'icon-alert-circle',
          position:"top-right",
          time:4000
        });
        this.showError(e);
      });
    }
  }
};
</script>